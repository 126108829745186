import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf,
  Font,
} from "@react-pdf/renderer";
import { TailSpin } from "react-loader-spinner";

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
});

// PDF styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: "Roboto",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  headerBox: {
    width: "40%",
    border: "1px solid #000",
    padding: 5,
    fontSize: 8,
  },
  header: {
    fontSize: 14,
    textAlign: "center",
    width: "20%",
  },
  subHeader: {
    fontSize: 10,
    textAlign: "center",
    marginBottom: 10,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2",
    textAlign: "center",
  },
  tableCol: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 8,
    fontWeight: "bold",
  },
  tableCell: {
    margin: 5,
    fontSize: 8,
  },
  bottomSection: {
    marginTop: 20,
    fontSize: 8,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  signatureSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 50,
    fontSize: 8,
  },
  signatureBox: {
    width: "30%",
    borderTop: "1px solid #000",
    textAlign: "center",
    paddingTop: 5,
  },
  headerLine: {
    marginBottom: 5,
  },
});

// Receipt document
const ReceiptDocument = ({ products }) => {
  const totalPrice = products
    .reduce((total, product) => total + product.price * product.quantity, 0)
    .toFixed(2);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerContainer}>
          <View style={styles.headerBox}>
            <Text className="mb-3">Получател</Text>
            <Text style={styles.headerLine}>
              гр. (с.) ___________________________________________________
            </Text>
            <Text style={styles.headerLine}>
              ул. № ____________________________________________________
            </Text>
            <Text style={styles.headerLine}>
              М.О.Л ___________________________________________________
            </Text>
            <Text style={styles.headerLine}>
              ЕИК по Булстат _________________________________________
            </Text>
          </View>
          <Text style={styles.header}>СТОКОВА РАЗПИСКА</Text>
          <View style={styles.headerBox}>
            <Text className="mb-3">Доставчик</Text>
            <Text style={styles.headerLine}>
              гр. (с.) Варна
            </Text>
            <Text style={styles.headerLine}>
              ул. № ЦАРЕВЕЦ 9000
            </Text>
            <Text style={styles.headerLine}>
              М.О.Л ___________________________________________________
            </Text>
            <Text style={styles.headerLine}>
              ЕИК по Булстат _________________________________________
            </Text>
          </View>
        </View>
        <Text style={styles.subHeader}>
          ЗА ПОЛУЧАВАНЕ НА СТОКОВО-МАТЕРИАЛНИ ЦЕННОСТИ
        </Text>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>№</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Номер на продукт</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>
                Наименование на стоката или услугата
              </Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Количество</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Ед. цена</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Сума</Text>
            </View>
          </View>

          {products.map((product, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{index + 1}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{product.sku}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{product.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{product.quantity}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {product.price || "N/A"} лв.
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {(product.price * product.quantity).toFixed(2) || "N/A"} лв.
                </Text>
              </View>
            </View>
          ))}
          <View style={styles.tableRow}>
            <View style={styles.tableCol} />
            <View style={styles.tableCol} />
            <View style={styles.tableCol} />
            <View style={styles.tableCol} />
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Общо:</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{totalPrice} лв.</Text>
            </View>
          </View>
        </View>

        <View style={styles.bottomSection}>
          <Text>Сума на стоката с думи: _______________________</Text>
          <Text>Вид транспорт: _____________________________</Text>
        </View>

        <View style={styles.signatureSection}>
          <View style={styles.signatureBox}>
            <Text>Изготвил</Text>
          </View>
          <View style={styles.signatureBox}>
            <Text>Приел</Text>
          </View>
          <View style={styles.signatureBox}>
            <Text>Издал</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

function GoodsReceipt() {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productsTotal, setProductsTotal] = useState(0);
  const [showZeroQuantity, setShowZeroQuantity] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProductsData();
  }, []);

  const updateSelectedProductsTotal = (updatedProducts) => {
    let total = 0;
    updatedProducts.forEach((product) => {
      total += product.price * product.quantity;
    });

    setProductsTotal(total > 0 ? total.toFixed(2) : (0.0).toFixed(2));
  };

  const fetchProductsData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://felisabg.com/wp-json/wc/v3/products",
        {
          auth: {
            username: process.env.REACT_APP_WC_CONSUMER_KEY,
            password: process.env.REACT_APP_WC_CONSUMER_SECRET,
          },
          params: {
            per_page: 300,
            exclude: ["34529", "29634"],
          },
        }
      );
      const sortedProducts = response.data.sort((a, b) => b.stock_quantity - a.stock_quantity);
      setProducts(sortedProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleProductSelection = (product, quantity) => {
    let updatedProducts = [...selectedProducts];
    const existingProductIndex = updatedProducts.findIndex(
      (p) => p.id === product.id
    );

    if (existingProductIndex > -1) {
      if (quantity > 0) {
        updatedProducts[existingProductIndex].quantity = quantity;
      } else {
        updatedProducts.splice(existingProductIndex, 1);
      }
    } else {
      if (quantity > 0) {
        updatedProducts.push({ ...product, quantity });
      }
    }

    updateSelectedProductsTotal(updatedProducts);
    setSelectedProducts(updatedProducts);
  };

  const generateReceipt = async () => {
    try {
      await Promise.all(
        selectedProducts.map(async (product) => {
          await axios.put(
            `https://felisabg.com/wp-json/wc/v3/products/${product.id}`,
            {
              stock_quantity: product.stock_quantity + product.quantity,
            },
            {
              auth: {
                username: process.env.REACT_APP_WC_CONSUMER_KEY,
                password: process.env.REACT_APP_WC_CONSUMER_SECRET,
              },
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        })
      );

      const receiptBlob = await pdf(
        <ReceiptDocument products={selectedProducts} />
      ).toBlob();
      const url = URL.createObjectURL(receiptBlob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "стокова_разписка.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      alert(
        "Генерирана е стокова разписка и количествата на продуктите са обновени!"
      );

      setSelectedProducts([]);
      setProductsTotal(0);
      fetchProductsData();
    } catch (error) {
      console.error("Error updating products:", error);
    }
  };

  const toggleShowZeroQuantity = () => {
    setShowZeroQuantity(!showZeroQuantity);
  };

  const clearSelectedProducts = () => {
    setSelectedProducts([]);
    setProductsTotal(0);

    document.querySelectorAll('input[type="number"]').forEach((input) => {
      input.value = "";
    });
  };

  const filteredProducts = showZeroQuantity
    ? products
    : products.filter((product) => product.stock_quantity > 0);

  return (
    <div className="col-span-12 lg:col-span-10 flex justify-center">
      <div className="flex flex-col gap-5 w-11/12">
        <div className="bg-white rounded p-3">
          <div className="flex justify-between items-center mb-3">
            <span className="font-semibold">Стокова разписка</span>
            <div>
              <button
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 mr-3 rounded"
                onClick={toggleShowZeroQuantity}>
                {showZeroQuantity
                  ? "Скрий продукти с 0 количество"
                  : "Покажи продукти с 0 количество"}
              </button>
              {selectedProducts.length > 0 && (
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  onClick={clearSelectedProducts}>
                  Изчисти избраните продукти
                </button>
              )}
            </div>
          </div>
          <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 mt-3">
            {loading ? (
              <div className="flex justify-center items-center py-10">
                <TailSpin color="#00BFFF" height={50} width={50} />
              </div>
            ) : (
              <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
                <thead>
                  <tr>
                    <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                      № на продукт
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                      Име на продукт
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                      Количество
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                      Цена на бройка
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                      Добави количество
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filteredProducts.map((product) => (
                    <tr key={product.id}>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-900">
                        {product.sku}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-900">
                        {product.name}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        {product.stock_quantity ? product.stock_quantity : 0}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        {product.price ? product.price : 0}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <input
                          type="number"
                          min="0"
                          className="border border-gray-300 rounded p-1"
                          onChange={(e) =>
                            handleProductSelection(
                              product,
                              parseInt(e.target.value, 10) || 0
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <div className="flex justify-between mt-4">
            <button
              style={{ width: "60%" }}
              className="bg-blue-500 text-center hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={generateReceipt}>
              Създай стокова разписка
            </button>
            <div
              style={{ width: "40%" }}
              className="bg-blue-500 text-center hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-3">
              Общо: {productsTotal} лв.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoodsReceipt;
