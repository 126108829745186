import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import AddPurchaseDetails from "../components/AddPurchaseDetails";
import { TailSpin } from "react-loader-spinner";
import AuthContext from "../AuthContext";

function PurchaseDetails() {
  const [showPurchaseModal, setPurchaseModal] = useState(false);
  const [purchase, setAllPurchaseData] = useState([]);
  const [products, setAllProducts] = useState([]);
  const [analytics, setAnalytics] = useState({});
  const [orders, setOrders] = useState([]); // State to store the last 30 orders
  const [updatePage, setUpdatePage] = useState(true);
  const [loading, setLoading] = useState(true);

  const authContext = useContext(AuthContext);

  useEffect(() => {
    fetchPurchaseData();
    fetchProductsData();
    fetchAnalyticsData();
    fetchOrdersData(); // Fetch the last 30 orders
  }, [updatePage]);

  // Fetching Data of All Purchase items
  const fetchPurchaseData = () => {
    fetch(`http://localhost:4000/api/purchase/get/${authContext.user}`)
      .then((response) => response.json())
      .then((data) => {
        setAllPurchaseData(data);
      })
      .catch((err) => console.log(err));
  };

  // Fetching Data of All Products
  const fetchProductsData = () => {
    fetch(`http://localhost:4000/api/product/get/${authContext.user}`)
      .then((response) => response.json())
      .then((data) => {
        setAllProducts(data);
      })
      .catch((err) => console.log(err));
  };

  // Fetching WooCommerce Analytics Data
  const fetchAnalyticsData = async () => {
    setLoading(true);
    const currentYear = new Date().getFullYear();
    const startDate = `${currentYear}-01-01`;

    try {
      const response = await axios.get('https://felisabg.com/wp-json/wc/v3/reports/sales', {
        auth: {
          username: process.env.REACT_APP_WC_CONSUMER_KEY,
          password: process.env.REACT_APP_WC_CONSUMER_SECRET
        },
        params: {
          date_min: startDate
        }
      });
      setAnalytics(response.data[0]);
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetching the last 30 orders from WooCommerce
  const fetchOrdersData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://felisabg.com/wp-json/wc/v3/orders', {
        auth: {
          username: process.env.REACT_APP_WC_CONSUMER_KEY,
          password: process.env.REACT_APP_WC_CONSUMER_SECRET
        },
        params: {
          per_page: 30,
          orderby: 'date',
          order: 'desc'
        }
      });
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  // Modal for Sale Add
  const addSaleModalSetting = () => {
    setPurchaseModal(!showPurchaseModal);
  };

  // Handle Page Update
  const handlePageUpdate = () => {
    setUpdatePage(!updatePage);
  };

  return (
    <div className="col-span-12 lg:col-span-10 flex justify-center">
      <div className="flex flex-col gap-5 w-11/12">
        {showPurchaseModal && (
          <AddPurchaseDetails
            addSaleModalSetting={addSaleModalSetting}
            products={products}
            handlePageUpdate={handlePageUpdate}
            authContext={authContext}
          />
        )}
        {/* Analytics Section */}
        <div className="bg-white rounded p-3 mb-5">
          <span className="font-semibold px-4">Данни от продажби</span>
          {loading ? (
            <div className="flex justify-center items-center py-10">
              <TailSpin color="#00BFFF" height={50} width={50} />
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 p-5">
              <div className="bg-gray-100 p-5 rounded shadow">
                <h3 className="font-bold">Оборот</h3>
                <p>{analytics.total_sales} лв.</p>
              </div>
              <div className="bg-gray-100 p-5 rounded shadow">
                <h3 className="font-bold">Общ брой продажби</h3>
                <p>{analytics.total_orders}</p>
              </div>
              <div className="bg-gray-100 p-5 rounded shadow">
                <h3 className="font-bold">Общ брой клиенти</h3>
                <p>{analytics.total_customers}</p>
              </div>
              <div className="bg-gray-100 p-5 rounded shadow">
                <h3 className="font-bold">Средна сума на продажба</h3>
                <p>{analytics.average_sales} лв.</p>
              </div>
            </div>
          )}
        </div>
        {/* Table */}
        <div className="overflow-x-auto rounded-lg border bg-white border-gray-200">
          <div className="flex justify-between pt-5 pb-3 px-3">
            <div className="flex gap-4 justify-center items-center">
              <span className="font-bold">Последни 30 поръчки</span>
            </div>
          </div>
          <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
            <thead>
              <tr>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Номер на поръчката
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Клиент
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Дата
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Обща сума
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {orders.map((order) => (
                <tr key={order.id}>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-900">
                    {order.id}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order.billing.first_name} {order.billing.last_name}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {new Date(order.date_created).toLocaleDateString()}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order.total} лв.
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PurchaseDetails;
