import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import AddProduct from "../components/AddProduct";
import UpdateProduct from "../components/UpdateProduct";
import AuthContext from "../AuthContext";
import { TailSpin } from "react-loader-spinner"; // Import loader component

function Inventory() {
  const [showProductModal, setShowProductModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateProduct, setUpdateProduct] = useState(null);
  const [products, setAllProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [productName, setProductName] = useState(""); // State for product name filter
  const [debouncedProductName, setDebouncedProductName] = useState(""); // Debounced state for product name filter
  const [updatePage, setUpdatePage] = useState(true);
  const [stores, setAllStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lowStockCount, setLowStockCount] = useState(0);
  const [outOfStockCount, setOutOfStockCount] = useState(0);

  const authContext = useContext(AuthContext);

  useEffect(() => {
    fetchProductsData();
    fetchSalesData();
  }, [updatePage, debouncedSearchTerm, debouncedProductName]);

  useEffect(() => {
    const lowStock = products.filter(product => product.stock_quantity > 5).length;
    const outOfStock = products.filter(product => product.stock_quantity < 1).length;

    setLowStockCount(lowStock);
    setOutOfStockCount(outOfStock);
  }, [products]);

  // Debounce search term
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setDebouncedProductName(productName); // Debounce product name filter
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, productName]);

  // Fetching Data of All Products from WooCommerce
  const fetchProductsData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://felisabg.com/wp-json/wc/v3/products', {
        auth: {
          username: process.env.REACT_APP_WC_CONSUMER_KEY,
          password: process.env.REACT_APP_WC_CONSUMER_SECRET
        },
        params: {
          per_page: 300,
          sku: debouncedSearchTerm,
          search: debouncedProductName, // Add product name filter
          exclude: ['34529', '29634']
        }
      });
      const sortedProducts = response.data.sort((a, b) => b.stock_quantity - a.stock_quantity);
      setAllProducts(sortedProducts);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetching all stores data
  const fetchSalesData = () => {
    fetch(`http://localhost:4000/api/store/get/${authContext.user}`)
      .then((response) => response.json())
      .then((data) => {
        setAllStores(data);
      });
  };

  // Modal for Product ADD
  const addProductModalSetting = () => {
    setShowProductModal(!showProductModal);
  };

  // Modal for Product UPDATE
  const updateProductModalSetting = (selectedProductData) => {
    setUpdateProduct(selectedProductData);
    setShowUpdateModal(true);
  };

  // Handle Page Update
  const handlePageUpdate = () => {
    setUpdatePage(!updatePage);
  };

  // Handle Search Term
  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle Product Name Filter
  const handleProductName = (e) => {
    setProductName(e.target.value);
  };

  return (
    <div className="col-span-12 lg:col-span-10 flex justify-center">
      <div className="flex flex-col gap-5 w-11/12">
        <div className="bg-white rounded p-3">
          <span className="font-semibold px-4">Склад</span>
          <div className="flex flex-col md:flex-row justify-center items-center">
            <div className="flex flex-col p-10 w-full md:w-3/12">
              <span className="font-semibold text-blue-600 text-base">
                Общ брой продукти
              </span>
              <span className="font-semibold text-gray-600 text-base">
                {products.length}
              </span>
            </div>
            <div className="flex flex-col gap-3 p-10 w-full md:w-3/12 border-y-2 md:border-x-2 md:border-y-0">
              <span className="font-semibold text-red-600 text-base">
                Наличност
              </span>
              <div className="flex gap-8">
                <div className="flex flex-col">
                  <span className="font-semibold text-gray-600 text-base">
                    {lowStockCount}
                  </span>
                  <span className="font-thin text-gray-400 text-xs">
                    с ниска наличност
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold text-gray-600 text-base">
                    {outOfStockCount}
                  </span>
                  <span className="font-thin text-gray-400 text-xs">
                    не са в наличност
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showProductModal && (
          <AddProduct
            addProductModalSetting={addProductModalSetting}
            handlePageUpdate={handlePageUpdate}
          />
        )}
        {showUpdateModal && updateProduct && (
          <UpdateProduct
            updateProductData={updateProduct}
            updateModalSetting={setShowUpdateModal}
            fetchProductsData={fetchProductsData}
          />
        )}

        {/* Filter and Search */}
        <div className="flex justify-between pt-5 pb-3 px-3">
          <div className="flex gap-4 justify-center items-center">
            <span className="font-bold">Продукти</span>
            <div className="flex justify-center items-center px-2 border-2 rounded-md">
              <img
                alt="search-icon"
                className="w-5 h-5"
                src={require("../assets/search-icon.png")}
              />
              <input
                className="border-none outline-none focus:border-none text-xs"
                type="text"
                placeholder="Търси по номер..."
                value={searchTerm}
                onChange={handleSearchTerm}
              />
            </div>
            <div className="flex justify-center items-center px-2 border-2 rounded-md">
              <img
                alt="search-icon"
                className="w-5 h-5"
                src={require("../assets/search-icon.png")}
              />
              <input
                className="border-none outline-none focus:border-none text-xs"
                type="text"
                placeholder="Търси по име..."
                value={productName}
                onChange={handleProductName}
              />
            </div>
          </div>
        </div>

        {/* Table  */}
        <div className="overflow-x-auto rounded-lg border bg-white border-gray-200">
          {loading ? (
            <div className="flex justify-center items-center py-10">
              <TailSpin color="#00BFFF" height={50} width={50} />
            </div>
          ) : (
            <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
              <thead>
                <tr>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    № на продукт
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Име на продукт
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Количество
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    Видим ли е?
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {products.map((element, index) => (
                  <tr key={element.id}>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-900">
                      {element.sku}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-900">
                      {element.name}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {element.stock_quantity ? element.stock_quantity : 0}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {element.catalog_visibility === 'visible' ? "Да" : "Не"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <span
                        className="text-green-700 cursor-pointer"
                        onClick={() => updateProductModalSetting(element)}
                      >
                        Редактирай
                      </span>
                      {/* <span
                        className="text-red-600 px-2 cursor-pointer"
                        onClick={() => deleteItem(element.id)}
                      >
                        Изтрий
                      </span> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

export default Inventory;
