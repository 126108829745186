import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";

export default function UpdateProduct({
  updateProductData,
  updateModalSetting,
  fetchProductsData
}) {
  const { id, name, manufacturer, description, stock_quantity, catalog_visibility } = updateProductData;
  const [product, setProduct] = useState({
    productID: id,
    name: name,
    manufacturer: manufacturer,
    description: description.replace(/<\/?[^>]+(>|$)/g, ""), // Remove HTML tags
    stock_quantity: stock_quantity,
    catalog_visibility: catalog_visibility
  });
  const [isLoading, setIsLoading] = useState(false); // State for loading
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setProduct({
      productID: id,
      name: name,
      manufacturer: manufacturer,
      description: description.replace(/<\/?[^>]+(>|$)/g, ""), // Remove HTML tags
      stock_quantity: stock_quantity,
      catalog_visibility: catalog_visibility
    });
  }, [updateProductData]);

  const handleInputChange = (key, value) => {
    setProduct({ ...product, [key]: value });
  };

  const updateProduct = async () => {
    setIsLoading(true); // Start loading
    try {
      await axios.put(`https://felisabg.com/wp-json/wc/v3/products/${product.productID}`, 
        {
          name: product.name,
          stock_quantity: product.stock_quantity,
          catalog_visibility: product.catalog_visibility,
          description: `<p>${product.description}</p>`,
          manufacturer: product.manufacturer
        },
        {
          auth: {
            username: process.env.REACT_APP_WC_CONSUMER_KEY,
            password: process.env.REACT_APP_WC_CONSUMER_SECRET
          },
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      fetchProductsData();
      updateModalSetting(false); // Close modal
    } catch (error) {
      console.error('Error updating product:', error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => updateModalSetting(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                {isLoading && (
                  <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-75 z-50">
                    <TailSpin color="#00BFFF" height={50} width={50} />
                  </div>
                )}
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PlusIcon
                        className="h-6 w-6 text-blue-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left ">
                      <Dialog.Title
                        as="h3"
                        className="text-lg mb-3 font-semibold leading-6 text-gray-900 ">
                        Редактирай
                      </Dialog.Title>
                      <form action="#">
                        <div className="grid gap-4 mb-4 sm:grid-cols-2">
                          <div>
                            <label
                              htmlFor="name"
                              className="block mb-2 text-sm font-medium text-gray-900">
                              Име на продукт
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={product.name}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              placeholder="Име на продукт"
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="stock_quantity"
                              className="block mb-2 text-sm font-medium text-gray-900">
                              Количество на склад
                            </label>
                            <input
                              type="number"
                              name="stock_quantity"
                              id="stock_quantity"
                              value={product.stock_quantity}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              placeholder="Количество"
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <label
                              htmlFor="catalog_visibility"
                              className="block mb-2 text-sm font-medium text-gray-900">
                              Видимост в каталога
                            </label>
                            <select
                              name="catalog_visibility"
                              id="catalog_visibility"
                              value={product.catalog_visibility}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                              <option value="visible">Активен в магазин</option>
                              <option value="hidden">Скрит</option>
                            </select>
                          </div>
                          <div className="sm:col-span-2">
                            <label
                              htmlFor="description"
                              className="block mb-2 text-sm font-medium text-gray-900">
                              Описание
                            </label>
                            <textarea
                              id="description"
                              rows="5"
                              name="description"
                              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              placeholder="Продуктово описание"
                              value={product.description}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }>
                            </textarea>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                    onClick={updateProduct}>
                    Редактирай
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => updateModalSetting(false)}
                    ref={cancelButtonRef}>
                    Отмени
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
